<template>
  <div class="contributors pageInit">
    <h1 class="pageTitle textColor" v-if="client=='inpixon'||client=='Aruba'">Attendees</h1>
    <h1 class="pageTitle textColor" v-else>Contributors</h1>
    <div class="searchContainer">
      <!--<searchBox style="margin-right: -90px" :showGo="true" @go="searchPerson" @change="inputChange"-->
      <!--           ref="refSearch"></searchBox>-->
      <searchBox style="margin-right: -90px" :showGo="true" @clickGoBtn="searchPerson" ref="refSearch"></searchBox>
    </div>
    <div class="contributorsContainer"
         v-if="showAttendees"
         v-infinite-scroll="loadAttendees"
         infinite-scroll-disabled="isBusy"
    >
      <contributor :person="item" v-for="item in person" :key="item" class="oneContributor"
                   @clickDetail="clickDetail"></contributor>
    </div>

    <!--<div class="contributorsContainer"-->
    <!--     v-if="showAttendees"-->
    <!--&gt;-->
    <!--  <contributor :person="item" v-for="item in person" :key="item" class="oneContributor"-->
    <!--               @clickDetail="clickDetail"></contributor>-->
    <!--</div>-->
    <!--contributor detail 弹窗（v-if=false 会销毁组件，否则里面的信息打开第一次后就不刷新了）-->
    <contributorDetail v-model="showDialog" :title="contributorDetailTitle" :data="personDetail"
                       v-if="showDialog"></contributorDetail>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  watch,
  onBeforeMount,
  onMounted,
  onBeforeUnmount,
  onActivated
} from "vue";
import searchBox from "@/components/tools/search.vue";
import search from "@/components/tools/search.vue";
import contributor from "@/components/page/contributor.vue";
import contributorDetail from "@/components/page/contributorDetail.vue";
import {ElLoading, ElMessageBox} from 'element-plus';
import {useStore} from 'vuex';
import axios from '@/api/http.js'

export default defineComponent({
  name: "Contributors",
  components: {
    search,
    searchBox,
    contributor,
    contributorDetail,
    ElLoading
  },
  setup(props, context) {
    const refSearch = ref(null);//用来查询的时候获取searhBox组件的input的值
    const client = localStorage.getItem("eventClient")
    const store = useStore();
    const searchInput = ref(null);
    const data = reactive({
      isBusy: true,//当前是否在查询分页
      total:0,//当前查询的总数
      pageSize: 20,
      currentPage: 1,
      contributorDetailTitle: 'Attendees',
      showAttendees: true,
      person: [
        // {
        //   id: '1',
        //   name: 'Jimmy Chan',
        //   firstName: 'Jimmy',
        //   lastName: 'Chan',
        //   title: 'Manager',
        //   desc: 'Nonummy Nibh',
        //   img: ''
        // },
        // {
        //   id: '2',
        //   name: 'Haocheng Xu',
        //   firstName: 'Haocheng',
        //   lastName: 'Xu',
        //   title: 'developer',
        //   desc: 'Nonummy Nibh',
        //   img: require("@/assets/imgs/ironMan.jpg")
        // },
        // {
        //   id: '3',
        //   name: 'Neo Chan',
        //   firstName: 'Neo',
        //   lastName: 'Chan',
        //   title: 'developer',
        //   desc: 'Nonummy Nibh',
        //   img: ''
        // },
        // {
        //   id: '4',
        //   name: 'Xiang Tan',
        //   firstName: 'Xiang',
        //   lastName: 'Tan',
        //   title: 'developer',
        //   desc: 'Nonummy Nibh',
        //   img: ''
        // },
        // {id: '5', name: 'Sun Cai', firstName: 'Sun', lastName: 'Cai', title: 'developer', desc: 'Nonummy Nibh', img: ''},
      ],
      personCache: [],
      showDialog: false,
      personDetail: {},
    });
    if (client == 'inpixon') {
      data.contributorDetailTitle = 'Contributors'
    } else {
      data.contributorDetailTitle = 'Attendees'
    }
    const formatData = (data) => {
      let arr = [];
      data.map(item => {
        let obj = {};
        obj.id = item.id;
        obj.name = item.name;
        obj.firstName = item.firstName;
        obj.lastName = item.lastName;
        obj.title = item.title;
        obj.descc = item.descc;
        obj.image = item.image || item.img;
        obj.bio = item.bio;
        obj.company = item.company;
        obj.email = item.email;
        obj.linkedInURL = item.linkedInURL;
        obj.presenterStatus = item.presenterStatus;
        arr.push(obj);
      })
      console.log("==== arr ===", arr);
      return arr
    }
    const getData = () => {
      const loading = ElLoading.service({
        lock: true,
      })
      axios.request({
        method: 'PATCH',
        url: '/virtual/attendees',
        data: {
          meetingId: "a7yyxczuty0qpjo2393gmyad",
          pageSize: data.pageSize,
          index: data.currentPage - 1,
          // keyword: keyword || refKeyword
          // meetingId: localStorage.eventsMeetingId,

        }
      }).then(res => {
        loading.close();
        console.log("res:", res.data.data);
        data.person = data.person.concat(formatData(res.data.data));
        data.personCache = JSON.parse(JSON.stringify(data.person));
        data.isBusy = false;
        console.log("====isBusy！！！！！！！！！！！！！！！ =====",data.isBusy)
      }).catch(err => {
        loading.close();
      })
    }
    const clickDetail = (person) => {
      console.log("====person =====", person)
      data.showDialog = true;
      data.personDetail = person;
      console.log("personDetail:", data.personDetail)
    }
    // 监听搜索框
    const inputChange = (param) => {
      console.log("====param =====", param)
      if (param == "") {
        data.person = JSON.parse(JSON.stringify(data.personCache));//获取回最原始的数据
      }
    }
    const searchPerson = (keyword) => {
      data.currentPage = 1;
      let refKeyword = refSearch.value.input1.trim();
      const loading = ElLoading.service({
        lock: true,
      })
      axios.request({
        method: 'PATCH',
        url: '/virtual/attendees',
        data: {
          meetingId: "a7yyxczuty0qpjo2393gmyad",
          pageSize: data.pageSize,
          index: data.currentPage - 1,
          keyword: keyword || refKeyword
        }
      }).then(res => {
        console.log('person:', res.data.data);
        data.person = res.data.data;
        data.currentPage += 1;
        loading.close();
      }).catch(err => {
        console.log(err);
        loading.close();
      })
    }
    const loadAttendees = (keyword) => {
      if(data.currentPage>1){
        let isLastPage = data.currentPage >= Math.ceil(data.total/data.pageSize);
        console.log("====currentPage =====",data.currentPage)
        console.log("====total =====",data.total)
        console.log("====pageSize =====",data.pageSize)
        console.log("====data.total/data.pageSize =====",data.total/data.pageSize)
        console.log("====Math.ceil(data.total/data.pageSize) =====",Math.ceil(data.total/data.pageSize))
        console.log("====isLastPage =====",isLastPage)
        if(isLastPage){
          ElMessageBox.alert('No more.', {
            confirmButtonText: 'OK',
          })
          return
        }
      }

      console.log("====loadAttendees =====",)
      let refKeyword = refSearch.value.input1.trim();
      data.isBusy = true;
      const loading = ElLoading.service({
        lock: true,
      })
      axios.request({
        method: 'PATCH',
        url: '/virtual/attendees',
        data: {
          meetingId: "a7yyxczuty0qpjo2393gmyad",
          pageSize: data.pageSize,
          index: data.currentPage - 1,
          keyword: keyword || refKeyword
          // meetingId: localStorage.eventsMeetingId,
        }
      }).then(res => {
        loading.close();
        console.log("res:", res.data.data);
        data.person = data.person.concat(formatData(res.data.data));
        data.personCache = JSON.parse(JSON.stringify(data.person));
        data.currentPage += 1;
        data.isBusy = false;
        data.total = res.data.total;
        console.log("====isBusy！！！！！！！！！！！！！！！ =====",data.isBusy)
      }).catch(err => {
        loading.close();
      })
    }
    watch(() => data.showDialog, (newVal, oldVal) => {
      console.log("newVal", newVal)
      if (newVal == false) {
        data.personDetail = {};
      }
    });
    // getData();


    onActivated(() => {
      // console.log("==== ref=====",searchInput.value.input1)
      // searchInput.value.input1 = '';//将查询框的值清空
      data.person = JSON.parse(JSON.stringify(data.personCache));
      refSearch.value.input1 = "";
    });
    onBeforeMount(() => {
    });
    onMounted(() => {
      loadAttendees();
    });
    onBeforeUnmount(() => {

    })
    return {
      ...toRefs(data),
      refSearch,
      clickDetail,
      store,
      getData,
      formatData,
      searchInput,
      searchPerson,
      inputChange,
      client,
      loadAttendees
    };
  },
});
</script>
<style scoped lang='scss'>
.contributors {
  background: #f0f9fe;
  //overflow-y: scroll;
  //width: 100%;

  .searchContainer {
    display: flex;
    padding: 0px 0 20px 0;
    align-items: baseline;
  }

  .contributorsContainer {
    display: flex;
    flex-wrap: wrap;
    padding-right: 50px;
    margin-left: -30px;
    //height: 700px;
    height: calc(100vh - 220px);
    overflow-y: auto;
    .oneContributor {
      margin: 0 0 30px 30px
    }
  }
}
</style>
<style lang="scss" scoped>
@media screen and (min-width: 320px) and (max-width: 992px) {
  .contributors .contributorsContainer {
    justify-content: center;
  }

}
</style>



